<template>
<div
  class="event-button-section"
>
    <div class="award-buttons" v-if="isAward">
      <button class="meetingPlanner" v-if="isBooked">Event Page </button>
      <router-link
        v-if="hasBankAccount && !isBooked"
        :to="{ name: 'BookAwardDetails', params: { awardId: eventId, slug } }"
      >
        <button class="bookNow">Book Now</button>
      </router-link>
    </div>
    <div class="event-buttons" :style='btnStyle' v-else>
        <button class="mp-button" v-if='mpIsNotAvailable()'  disabled>
          <i class="fa fa-calendar"></i> Online Meeting Planner is not available
        </button>
      <router-link
        v-else-if="isBooked && isMpActive()"
        :to="{ name: 'meetingPlanner', params: { eventId, slug  } }"
      >
        <button class="meetingPlanner">
          <b-row id="event-button-row" class="m-0">
            <b-col sm="4" class="eventCalendar">
              <i class="fa fa-calendar"></i>
            </b-col>
            <b-col sm="2" class="meetingPlannerText">Online Meeting Planner available</b-col>
          </b-row>
        </button>
      </router-link>
      <button v-else-if="isBooked && !isMpActive()" disabled class="mp-button">
        <i class="fa fa-calendar mp-icon"></i>
        Meeting Planner will be open at {{mpSubtractDays(mpActivation)}}
      </button>
      <router-link
        v-else-if="hasBankAccount && !isBooked "
        :to="{ name: 'BookEventDetails', params: { eventId, slug } }"
      >
        <button class="bookNow">Book Now</button>
      </router-link>
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import redirectToMeetingPlanner from '../../utils/mixins/redirectToMeetingPlanner';

export default {
  name: 'EventButtons',
  mixins: [redirectToMeetingPlanner],
  props: {
    isBooked: {
      type: Boolean,
      default: false,
    },
    eventId: {
      type: Number,
      default: null,
    },
    eventUrl: {
      type: String,
      default: null,
    },
    isAward: {
      type: Boolean,
      default: false,
    },
    mpActivation: {
      type: String,
      default: null,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    startDate: {
      type: String,
      default: null,
    },
    hasBankAccount: {
      type: Boolean,
      default: false,
    },
    displayParticipants: {
      type: Boolean,
      default: false,
    },
    participants: {
      type: Array,
      default: null,
    },
    slug: {
      type: String,
      default: null,
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/fetchUserInfo',
    }),
    btnStyle() {
      let marginTop = 0;
      if (this.mpIsNotAvailable() || (this.isBooked && !this.isMpActive())) {
        marginTop = '-12px';
      }
      return {
        marginTop,
      };
    },
  },
  methods: {
    moment(date) {
      return moment(new Date(date).toUTCString());
    },
    mpIsNotAvailable() {
      const {
        isBooked, isMpActive, isAvailable, displayParticipants, isActive, participants,
      } = this;
      return (isBooked && isMpActive() && !displayParticipants)
        || (isBooked && isMpActive() && !isAvailable(participants))
        || (isBooked && !isActive);
    },
    mpSubtractDays() {
      const fullDate = this.mpActivation
        ? this.moment(this.mpActivation)
        : this.moment(this.startDate).subtract(21, 'days');
      return `${fullDate.format('DD')} ${fullDate.format('MMM')}`;
    },
    isMpActive() {
      return (
        this.isActive && this.mpActivation
          ? this.moment(moment().format('YYYY-MM-DD')).isSameOrAfter(
            this.moment(this.mpActivation).format('YYYY/MM/DD'),
          )
          : this.moment(
            this.moment().add(21, 'days').format('YYYY-MM-DD'),
          ).isSameOrAfter(this.moment(this.startDate).format('YYYY/MM/DD'))
      );
    },
  },
};
</script>

<style scoped>
.event-button-section {
  position: absolute;
  top: 15px;
  right: 2px;
}
.event-button {
  margin-top: -12px;
}
.meetingPlanner {
  background: #f5f5dc00;
  width: 80px;
  font-size: 9px;
  padding: 5px 1px;
  border: 1px solid #bd9d5f;
  color: #bd9d5f;
  line-height: 1.1;
}
.meetingPlanner:hover {
  background: #bd9d5f;
  color: white;
  transition: 0.3s;
}
.meetingPlannerText:hover {
  margin-left: -14px;
  color: white;
}
.meetingPlanner > i {
  font-size: 21px;
}
.eventCalendar {
  padding: 1px;
  margin-left: 3px;
  font-size: 16px;
}
.meetingPlannerText {
  margin-left: -14px;
}
.bookNow {
  background: #bd9d5f;
  border: unset;
  color: white;
  cursor: pointer;
  font-size: 9px;
  line-height: 1.1;
  width: 80px;
  padding: 10px;
  border-radius: 0 10px 10px 10px;
}
.beforeMeetingPlannerOpens {
  width: 91px;
  font-size: 9px;
  height: 40px;
  background: #d8d4d4;
  border: none;
  color: gray;
}
.eventPage {
  width: 91px;
  height: 40px;
  font-size: 12px;
  border: 1px solid #bd9d5f;
  background: white;
  color: #bd9d5f;
}
.mp-button {
  padding: 0;
  width: 80px;
  font-size: 9px;
  border: none;
  color: #7f7f7f;
}
.mp-button:focus {
  outline: none;
}
.mp-icon {
  font-size: 9px;
  margin-right: 5px;
}
</style>
